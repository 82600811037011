export default {
  settings: (state) => state.settings,

  authOutdoor: (state) => state.authOutdoor,
  userOutdoor: (state) => state.userOutdoor,
  boxOutdoor: (state) => state.boxOutdoor,

  fakeHours: (state) => state.fakeHours,
  fakeHoursManned: (state) => state.fakeHoursManned,

  globalLoadingOutdoor: (state) => state.globalLoadingOutdoor,
  i18nDataOutdoor: (state) => state.i18nDataOutdoor,

  test: (state) => state.test,

  error: (state) => state.error,
};
