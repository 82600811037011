export default {
  /**
   * SETTINGS
   */
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },

  /**
   * AUTH
   */
  SET_AUTH_OUTDOOR(state, authOutdoor) {
    state.authOutdoor = authOutdoor;
  },

  /**
   * USER
   */
  SET_USER_OUTDOOR(state, userOutdoor) {
    state.userOutdoor = userOutdoor;
  },

  /**
   * BOX
   */
  SET_BOX_OUTDOOR(state, boxOutdoor) {
    state.boxOutdoor = boxOutdoor;
  },

  /**
   * FAKE HOURS
   */
  SET_FAKE_HOURS(state, fakeHours) {
    state.fakeHours = fakeHours;
  },
  SET_FAKE_HOURS_MANNED(state, fakeHoursManned) {
    state.fakeHoursManned = fakeHoursManned;
  },

  /**
   * GLOBAL LOADING
   */
  SET_GLOBAL_LOADING_OUTDOOR(state, globalLoadingOutdoor) {
    state.globalLoadingOutdoor = globalLoadingOutdoor;
  },

  /**
  * TRANSLATIONS
  */
  SET_I18N_OUTDOOR(state, i18nDataOutdoor) {
    state.i18nDataOutdoor = i18nDataOutdoor;
  },

  /**
   * SET TEST
   */
  SET_TEST(state, test) {
    state.test = test;
  },

  /**
   * SET ERROR
   */
  SET_ERROR(state, error) {
    state.error = error;
  },
};
